@import "../base/variables";

.slideUp, .slideDown {
  max-height: 0;
  overflow-y: hidden;
  transition: $transition-box;
}

.slideDown {
  max-height: 1000px ;
}
