// Layout

.u-float {
  &--left  { float: left !important; }
  &--right { float: right !important; }
}

.u-align{
  &--top    { vertical-align: top !important; }
  &--bottom { vertical-align: bottom !important; }
  &--middle { vertical-align: middle !important; }
}

.u-hide {
  display: none;
}

.u-hide__tablet {
  display: none;

  @media (min-width: $desk) {
    display: block;
  }
}

.u-hide__tablet--inline {
  display: none;

  @media (min-width: $desk) {
    display: inline;
  }
}

.u-hide__desktop {
  @media (min-width: $desk) {
    display: none;
  }
}

.u-show__desktop {
  @media (min-width: $desk) {
    display: block;
  }
}

.u-relative {
  position: relative;
}

.u-max-w-90 {
  max-width: 90%;
}

.u-max-w-20 {
  max-width: 20%;
}
