// fonts

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter/Inter-Medium.woff2') format('woff2'),
  url('../../fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
  url('../../fonts/Inter/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter/Inter-Bold.woff2') format('woff2'),
  url('../../fonts/Inter/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Regular.woff2') format('woff2'),
  url('../../fonts/Lato/Lato-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../../fonts/Lato/Lato-Bold.woff2') format('woff2'),
  url('../../fonts/Lato/Lato-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Galano Grotesque Alt';
  src: url('../../fonts/galano-grotesque/GalanoGrotesqueAlt-Regular.woff2') format('woff2'),
  url('../../fonts/galano-grotesque/GalanoGrotesqueAlt-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Josefin Sans';
  src: url('../../fonts/Josefin_Sans/JosefinSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
