.array-choice {
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em 0;

  &__header {
    .array-choice--item__heading {
      border: 1px solid var(--grey-7);
      & + .array-choice--item__heading {
        border-top: none;
      }
    }
  }

  &__options {
    display: flex;
    overflow-x: auto;
    text-align: center;
  }

  &--item {

    &--large {
      width: 8em !important;
    }

    border-color: var(--grey-7);
    border-style: solid;
    border-width: thin;
    border-left: none;

    cursor: pointer;

    &:hover,
    &--selected{
      background: var(--ink-1-light);

      .array-choice--item__option {
        border-top: 1px solid var(--ink-1);
      }
    }

    &__heading,
    &__option {
      padding: 10px 20px;
      //min-width: max-content;
      width: 9em;
    }

    &__heading {
      color: var(--ink-1);
      font-weight: bold;
      border-left: thin;
    }

    &__option {
      border-top: 1px solid var(--grey-7);
    }
  }
}
