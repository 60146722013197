%button-small {
  padding: 4px 8px;

  font-size: em(11);
  line-height: em(13);
}

%button-medium {
  padding: 8px 12px;

  font-size: em(14);
  line-height: em(17);
}

%button-large {
  padding: 12px 16px;

  font-size: em(18);
  line-height: em(22);
}

.button {
  @extend %button-reset;

  display: flex;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-family: var(--font-family-button);
  font-weight: 600;
  text-align: center;

  border-radius: $button-radius-base;
  transition: $transition-base;

  .left svg {
    margin-right: 8px;
  }
  .right svg {
    margin-left: 12px;
  }

  $c: &;

  &--small {
    @extend %button-small;
  }

  &--medium {
    @extend %button-medium;
  }

  &--large {
    @extend %button-large;
  }

  &-primary {
    @include button-color(var(--light), var(--ink-1), var(--ink-1-disable), var(--ink-1-darken));
  }

  &-primary--outline {
    @include button-color-outline(var(--ink-1), transparent, var(--ink-1-disable), var(--ink-1-darken));
  }

  &-secondary {
    @include button-color(var(--light), var(--ink-7), var(--ink-7-disable),var(--ink-7-darken));
  }

  &-secondary--outline {
    @include button-color-outline(var(--ink-7), transparent, var(--ink-7-disable), var(--ink-7-darken));
  }

  &-success {
    @include button-color(var(--light), var(--success), var(--success), var(--success-darken));
  }

  &-success--outline {
    @include button-color-outline(var(--success), transparent, var(--success), var(--success-darken));
  }

  &-error {
    @include button-color(var(--light), var(--error), var(--error), var(--error-darken));
  }

  &-error--outline {
    @include button-color-outline(var(--error), transparent, var(--error), var(--error-darken));
  }

  &-shadow-secondary {
    box-shadow: $button-shadow-secondary;
  }

  &--files-state {
    display: flex;
    justify-content: space-between;
    font-size: em(11);
    align-items: center;

    svg {
      max-width: 25px;
    }

    &--active {
      background: var(--ink-1-darken);
    }
  }
  &--centered {
    align-items: center;
  }
}

@include max-screen($desk-down) {
  .button {
    width: 100%;
    margin: 0.1rem;
  }
}

@include min-screen($desk) {
  .button {
    min-width: 40%;

    &--fixed {
      min-width: 250px;
    }

    &--width-fit {
      display: initial;
      width: fit-content;
      min-width: auto;
    }

    &--width-full {
      min-width: 100%;
    }
  }
}

.button--height-fit {
  height: fit-content;
}

.button-enabled {
  pointer-events: auto !important;
}

.button-disabled {
  pointer-events: none !important;
}

.button-tab {
  background: var(--gray-1);
  color: var(--ink-1);
  padding: 12px 16px;

  &--active {
    background: var(--ink-1);
    color: var(--light);
  }
}

.link-tab {
  color: var(--grey-6);
  padding: 12px 16px;

  &--active {
    color: var(--ink-1);
    text-decoration: underline;
    text-decoration-color: var(--ink-1);
  }
  &--2 {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    flex-basis: 50% !important;
    max-width: 50%;    
  }
}
