// Retina

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url("#{file-1x}");

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    background-image: url("#{file-2x}");
    background-size: $width-1x $height-1x;
  }
}

// Text

%text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%sr-only, .u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// List

%list-reset, .list-reset {
  padding: 0;
  list-style: none;
  margin: 0;
}

%list-inline, .list-inline {
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    padding-right: 20px;

    &:last-child {
      padding-right: 0;
    }
  }
}

%list--two-column, .list--two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
}

%button-reset, .button-reset {
  background: none;
  border-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: 0;
}

%text-uppercase {
  text-transform: uppercase;
  font-weight: bold;
}

%full-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

%overlay-image {

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    z-index: 1;
    position: absolute;
    transition: $transition-base;
  }

  img {
    z-index: 0;
  }
}

// Placeholder

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

// Functions

@function rem($pxval) {
  @return ($pxval / 16) * 1rem;
}

@function em($pxval, $base: 16) {
  @return ($pxval / $base) * 1em;
}

@mixin flex-div($width) {
  flex-basis: $width;
  width: $width;
  max-width: $width;
}

@mixin button-color($color, $color-bg, $color-disable, $color-hover) {
  color: $color;
  background: $color-bg;

  &:disabled {
    background: $color-disable;
    cursor: not-allowed;
  }

  &:hover {
    background: $color-hover;
  }
}

@mixin button-color-outline($color, $color-bg, $color-disable, $color-hover) {
  color: $color;
  background-color: $color-bg;
  border: 1px solid $color;

  &:disabled {
    color: $color-disable;
    border: 1px solid $color-disable;
    cursor: not-allowed;
  }

  &:hover {
    color: $color-hover;
    border-color: $color-hover;
  }
}

// https://stackoverflow.com/a/30250161/1331425
@mixin not($ignor-list...) {
                        @if (length($ignor-list) == 1) {
                          $ignor-list: nth($ignor-list, 1);
                        }

                        $not-output: '';
                        @each $not in $ignor-list {
                          $not-output: $not-output + ':not(#{$not})';
                        }

                        &#{$not-output} {
                          @content;
                        }
                      }

.small-cap, %small-cap {
  font-size: em(12);
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }
  @-o-keyframes #{$animationName} {
    @content;
  }
  @keyframes #{$animationName} {
    @content;
  }
}

@include keyframes(fromLeft) {
  0%{
    transform: translate3d(-100%,0,0);
  }

  100%{
    transform: translate3d(0,0,0);
  }
}

@include keyframes(fromRight) {

  0%{
    transform: translate3d(0,0,0);
  }

  100%{
    transform: translate3d(100%,0,0);
  }
}

@include keyframes(leftRight) {
  0% {
    transform: translate(-100%,0);
  }
  50% {
    transform: translate(0,0);
  }
  100%{
    transform: translate(100%,0);
  }
}


// Media Query Mixins
@mixin screen($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin max-screen($max-width) {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin max-screen-height($max-width) {
  @media only screen and (max-height: $max-width) {
    @content;
  }
}

@mixin min-screen($min-width) {
  @media only screen and (min-width: $min-width) {
    @content;
  }
}

@mixin screen-between($maxWidth, $maxHeight) {
  @media screen and (max-width: $maxWidth), screen and (max-height: $maxHeight) {
    @content;
  }
}

// Managing Z-Layers & icon font names
@function z($layer: 'base') {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for"#{$layer}". Property omitted.";
  }

  @return map-get($z-layers, $layer);
}


@function em($pixels, $context: $font-size) {
  @return #{$pixels/$context}em;
}

@keyframes pulse {
  0%  {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 1px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--grey-6);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ink-1);
    border-radius: 0;
  }
}
