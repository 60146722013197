// SCSS Component

.table-container {
  position: relative;
  overflow-x: auto;
}

.table {
  width: 100%;
  font-size: 12px; // text-sm
  text-align: left;
  border-collapse: collapse;

  & tbody {
    & tr {
      background-color: #ffffff; // bg-white
      border-radius: 4px;
      overflow: hidden;
      border-bottom: 1px solid #e5e7eb; // border-b

      &:hover {
        background-color: var(--ink-1-opacity);
      }

      & td {
        padding: 1rem .5rem;
      }

      & td.text-right {
        text-align: right; // text-right

        & a {
          font-weight: 300; // font-medium
        }
      }
    }
  }
}
