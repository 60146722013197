// Reset

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  color: var(--body-color);
  font-size: $font-size-root-phone;
  line-height: 1.4375;
  font-family: var(--font-family-base);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: var(--body-bg-color);


  @include min-screen($desk) {
    font-size: $font-size-root;
  }
}

// Typography

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

p {
  margin: 0 0 $spacer;
  font-family: var(--font-family-alt);
}

ol,
ul,
dl {
  margin: 0 0 $spacer;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 $spacer;
}

hr {
  box-shadow: none;
  border: none;
  border-top: 1px solid var(--grey-7);
  padding: 10px 0;
  margin: 24px 0;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

// Images

img {
  vertical-align: middle;
  max-width: 100%;
}

svg:not(.svg-ie)  {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

// custom hack for dropdown menu
.css-26l3qy-menu {
  position: relative !important;
}
