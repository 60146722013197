.container-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 99%; /* 1:1 Aspect Ratio */
    max-height: 95%;
}
  
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}