.scroll-to-top {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;

  .scroll-button {
    background-color: var(--ink-1);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: white;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, transform 0.3s;

    &:hover {
      transform: scale(1.1);
      background-color: var(--ink-1-darken);
    }

    &:focus {
      outline: none;
    }
  }
}
