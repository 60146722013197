.wizzard {

  &-inner {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__step {
    flex: auto;
    margin-top: $spacer;
    width: 100%;

    .step__progression {
      position: relative;

      height: 4px;
      background: var(--grey-7);

      .progress {
        position: absolute;
        height: 4px;
        width: 0;
        background: var(--ink-1);
      }
    }

    .step__number,
    .step__title {
      color: var(--grey-6);
    }

    .step__number {
      margin-top: 8px;
      font-size: em(14);
      font-weight: 400;
    }

    .step__title {
      font-size: em(18);
      font-weight: 700;
      letter-spacing: -0.5px;
    }

    &--active {
      .step__number,
      .step__title {
        color: var(--ink-1);
      }
    }

    &--no-mt {
      margin-top: 0;
    }

    &--complete {
      .step__number,
      .step__title {
        color: var(--grey-9);
        cursor: pointer;
      }
    }

    &--complete-signed {
      .step__number,
      .step__title {
        color: var(--grey-9);
      }
    }

    &--back-office {
      .step__number,
      .step__title {
        cursor: pointer;
      }
    }
  }
}

@include min-screen($desk) {
  .wizzard {
    max-width: $max-width-wrapper;
    margin-bottom: $spacer;

    &-inner {
      margin-bottom: 36px;
      flex-direction: row;
    }

    &__step {
      margin-top: $spacer-l;

      .step__number,
      .step__title {
        margin-right: 32px;
      }

      &:last-child {
        margin-right: 0;
      }

      &--no-mt {
        margin-top: 0;
      }
    }
  }
}
