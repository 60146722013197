@import "../base/variables";

$color: var(--ink-1);

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $color;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: $color;
}
.flatpickr-months .flatpickr-month {
  background: $color;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $color;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: $color;
}
.flatpickr-weekdays {
  background: $color;
}
span.flatpickr-weekday {
  background: $color;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $color;
  border-color: $color;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 $color;
  box-shadow: -10px 0 0 $color;
}
.flatpickr-day.week.selected {
  -webkit-box-shadow: -5px 0 0 $color, 5px 0 0 $color;
  box-shadow: -5px 0 0 $color, 5px 0 0 $color;
}
