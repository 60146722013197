/* prepare wrapper element */
div.currency-amount {
  position: relative;

  /* position the unit to the right of the wrapper */
  &:after {
    color: var(--ink-1);
    content: '€';
    font-size:20px;
    position: absolute;
    top: 25px;
    right: 15px;
    transition: all .05s ease-in-out;
  }

  input {
    color: var(--ink-1);
    font-weight: bold;
  }


}

/* handle Firefox (arrows always shown) */
@supports (-moz-appearance:none) {
  div.currency-amount {
    &:after {
      right: 50px;
    }
  }
}