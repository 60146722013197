input[type=email] {
  position: relative;

  &:after {
    color: var(--ink-1);
    content: '\1F4E7';
    font-size:25px;
    position: absolute;
    top: 20px;
    right: 50px;
    transition: all .05s ease-in-out;
  }

}

/* handle Firefox (arrows always shown) */
@supports (-moz-appearance:none) {
  input[type=email] {
    &:after {
      right: 50px;
    }
  }
}