$motion-main: $ease-out-quint;
$duration-main: .3s;

.fade-enter-active,
.fade-leave-active {
  transition: opacity $duration-main;
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

.fade-enter-active {
  // transition-delay: $duration-main;
}


// SCALE

.scale-enter-active,
.scale-leave-active {
  transition: .3s $ease-out-quint;
}

.scale-enter,
.scale-leave-to {
  opacity: 0;
  transform: scale(0);
}



// FADE DELAY

.fade-delay-enter-active,
.fade-delay-leave-active {
  transition: opacity .1s;
}

.fade-delay-enter,
.fade-delay-leave-to {
  opacity: 0
}

.fade-delay-enter-active {
  transition-delay: .1s;
}


// UP

.fade-in-up-enter-active {
  transition-property: opacity, transform;
  transition-duration: .5s;
  transition-timing-function: $ease-out-quint;
}

.fade-in-up-leave-active  {
  transition: .2s opacity $ease-out-quart;
}

.fade-in-up-enter-active {
  transition-delay: .2s;
}

.fade-in-up-enter {
  opacity: 0;
  transform: translate(0, 40px);
}

.fade-in-up-leave-active {
  opacity: 0;
}

// DOWN

.fade-in-down-enter-active {
  transition-property: opacity, transform;
  transition-duration: .5s;
  transition-timing-function: $ease-out-quint;
}

.fade-in-down-leave-active  {
  transition: .2s opacity $ease-out-quart;
}

.fade-in-down-enter-active {
  transition-delay: .2s;
}

.fade-in-down-enter {
  opacity: 0;
  transform: translate(0, -40px);
}

.fade-in-down-leave-active {
  opacity: 0;
}

// LEFT

.fade-in-left-enter-active {
  transition-property: opacity, transform;
  transition-duration: .5s;
  transition-timing-function: $ease-out-quint;
}

.fade-in-left-leave-active  {
  transition: .2s opacity $ease-out-quart;
}

.fade-in-left-enter-active {
  transition-delay: .2s;
}

.fade-in-left-enter {
  opacity: 0;
  transform: translate(-20px, 0);
}

.fade-in-left-leave-active {
  opacity: 0;
}

// LEFT FAST
.fade-in-left-fast-enter-active {
  transition-property: opacity, transform;
  transition-duration: .3s;
  transition-timing-function: $ease-out-quint;
}

.fade-in-left-fast-leave-active  {
  transition: .15s opacity $ease-out-quint;
}

.fade-in-left-fast-enter {
  opacity: 0;
  transform: translate(-15px, 0);
}

.fade-in-left-fast-leave-active {
  opacity: 0;
}


// LAZY LOADING

@keyframes images-lazy-loading {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

[lazy="loaded"] {
  animation: images-lazy-loading;
  animation-duration: 0.3s;
}

@keyframes ripple-effect {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  20% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}
