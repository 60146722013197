@import "../base/variables";
@import "../base/mixins";

.dzu {
  &-dropzone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 120px;
    overflow: hidden;
    margin: 0 auto;
    padding: 16px 24px 16px 48px;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border: 2px solid var(--ink-1);
    border-radius: 16px;
    outline: none;
    cursor: pointer;
  }
  &-dropzoneActive {
    background: linear-gradient(0deg, rgba(17, 157, 164, 0.1), rgba(17, 157, 164, 0.1)), #FFFFFF;
    border: 2px dashed var(--ink-1);
  }
  &-dropzoneDisabled {
    opacity: 0.5;

    *:hover {
      cursor: unset;
    }
  }
  &-input {
    display: none;
  }
  &-inputLabel,
  &-inputLabelWithFiles {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Helvetica', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
  }
  &-previewContainer {
    margin-top: 4px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    min-height: 60px;
    z-index: 1;
    border: 1px solid var(--grey-6);
    border-radius: $border-radius-base;
    box-sizing: border-box;
    height: 40px;
  }
  &-previewStatusContainer {
    display: flex;
    align-items: center;
  }
  &-previewFileName {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  &-previewImage {
    width: auto;
    max-height: 40px;
    max-width: 140px;
    border-radius: 4px;
  }
  &-previewButton {
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    cursor: pointer;
    opacity: 0.9;
    margin: 0 0 2px 10px;
  }
  &-submitButtonContainer {
    margin: 24px 0;
    z-index: 1;
  }
  &-submitButton {
    padding: 0 14px;
    min-height: 32px;
    background-color: #2484FF;
    border: none;
    border-radius: 4px;
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    &:disabled {
      background-color: #E6E6E6;
      color: #333333;
      cursor: unset;
    }
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  width: 55%;

  .icon__content {

    span {
      font-family: var(--font-family-base);
      font-size: em(18);
      letter-spacing: -0.5px;
    }
  }

}

.drag-and-drop-wrapper {
  text-align: center;

  span {
    font-family: var(--font-family-base);
    font-size: em(18);
    letter-spacing: -0.5px;
  }
}

.upload-progress {
  &__wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 24px 0 48px;
  }

  width: 50%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
        "content content content percent"
        "footer footer footer footer";

  &__content {
    grid-area: content;
  }

  &__percent-str {
    grid-area: percent;
  }

  &__bar {
    grid-area: footer;
    width: 0;
    height: 2px;
    background-color: var(--ink-1);
  }
}





















