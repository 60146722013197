.preview-doc-widgets {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.preview-doc-widgets {
  .button-preview {
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    background: var(--ink-1-darken);
    color: var(--light);
    border-radius: 5px;
    min-width: 100%;
    cursor: pointer;

    &:hover {
      background: var(--ink-1);
    }

    &--reverse {
      background: var(--ink-1);
      svg g path {
        fill: var(--light);
      }

      &:hover {
        background: var(--ink-1-darken);
      }
    }

    svg {
      max-width: 25px;
    }

    div {
      margin-left: 4px;
      font-size: em(10);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.preview-doc-widgets--download {
  border-top: 1px solid var(--grey-8);
  margin-top: 10px;
  .button-preview {
    height: 75px;
  }
}

.button-preview-download {
  span, svg {
    cursor: pointer;
  }
}