.dropdown-menu-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  cursor: pointer;
  background: var(--gray-1);
  border-color: transparent;
  color: var(--ink-1);
  padding: 12px 16px;
  border-radius: 12px;
}



.dropdown-icon {
  margin-left: 8px;
  font-size: 12px;

  svg {
    width: 20px;
    transition: transform 0.3s ease;
  }

  &.open svg {
    transform: rotate(180deg);
  }
}

.dropdown-menu {
  position: absolute;
  top: 110%;
  left: 0;
  min-width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  li {
    margin: 0;

    button {
      padding: 10px 20px;
      width: 100%;
      text-align: left;
      background: none;
      border: none;
      border-radius: 0;
      cursor: pointer;
      font-family: var(--font-family-button);
      font-weight: 600;

      &.dropdown-button__error {
        color: var(--error);

        &:hover {
          color: var(--light);
          background: var(--error);
        }
      }

      &.dropdown-button__warning {
        color: var(--warning);

        &:hover {
          color: var(--light);
          background: var(--warning);
        }
      }

      &.dropdown-button__success {
        color: var(--success);

        &:hover {
          color: var(--light);
          background: var(--success);
        }
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
