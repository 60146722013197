.sortable {
  .sortable-label {
    display: flex;
    svg {
      margin: 0 10px 0 0;
    }
    span {
      font-family: var(--font-family-button);
      font-size: 1.1em;
      font-weight: 500;
    }
  }

  .sortable-chosen {
    .sortable-label {
      svg {
        path {
          fill: var(--ink-1);
        }
      }
      span {
        color: var(--dark);
        font-weight: 900;
      }
    }
  }
}
