.pj-container-min-preview {
  width: 70px;
  text-align: center;
}

.pj-figure img {
  opacity: 1;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.pj-figure:hover img {
  opacity: .7;
  cursor: pointer;
}

.pj-modal-content-center {
  text-align: center;
}

.pj-download-icon {
  margin-right: 5px;
}

.pj-download-icon:hover {
  background-image: url("../../images/icons/download-icon-hover.svg");
}

.pj-preview .fill {
  stroke: var(--ink1)
}

.pj-preview:hover .fill {
  stroke: #f00; /*var(--ink-1-darken)*/
}