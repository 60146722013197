.login {
  height: 100vh;
  padding-top: 48px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: center;

  background: url("../../images/bg.jpg");
  background-size: cover;

  &__box {
    display: flex;
    justify-content: center;

    .box__cta--column {
      margin: 56px;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__footer {
    position: relative;

    color: var(--light);

    ul {
      @extend %list-reset;
      @extend %list-inline;
    }
  }
}

@include min-screen($desk) {

  .login__footer ul {
    position: absolute;
    top: 0;
    right: 62px;
  }
}

