/* prepare wrapper element */
div.percentage {
  position: relative;

  /* position the unit to the right of the wrapper */
  &:after {
    color: var(--ink-1);
    content: '%';
    font-size: 20px;
    position: absolute;
    top: 25px;
    right: 5px;
    transition: all .05s ease-in-out;
  }

}

/* handle Firefox (arrows always shown) */
@supports (-moz-appearance:none) {
  div.percentage {
    &:after {
      right: 50px;
    }
  }
}

div.group-percentage-select-range {
  &-label, &-content {
    border-radius: 4px;
    margin-top: 1em;
    padding: 1em;
  }
  &-label {
    border: .1em dotted var(--ink-1);
    background-color: var(--grey-7);
  }
  &-content {
    border: .1em solid var(--ink-1);
  }


}

div.percentage-range {

   border-radius: 4px;
   border: .1em solid var(--grey-7);

  &-label, &-content {
    //border-radius: 4px;
  }
  &-label {
    background-color: var(--grey-7);
    margin: 0;
    padding: 1em;
  }
  &-content {
    margin-top: 0;
    padding: 1em;
  }

  &-presentation {
      .arrow , .value {
        color: var(--ink-1);
      }
  }

}