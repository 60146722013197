.content-page-loader {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.page-loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.page-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1;
  margin: -90px 0 0 -50px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--ink-1);
  animation: spin 2s linear infinite;
  width: 73px;
  height: 73px;
}

.loader-text {
  position: fixed;
  left: 39%;
  top: 50%;
  z-index: 1;
  color: white;
  text-align: center;
  font-size: 20px;
}

@media only screen and (max-width: 573px) {
  .loader-text {
    left: 0%;
  }
}

@media only screen and (min-width: 580px) and (max-width: 767px) {
  .loader-text {
    left: 15%;
  }
}

/* @media query pour les tablettes et écrans moyens */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .loader-text {
    left: 18%;
  }
}

/* @media query pour les tablettes et écrans moyens */
@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .loader-text {
    left: 25%;
  }
}

/* @media query pour les écrans plus larges (par exemple, ordinateurs de bureau) */
@media only screen and (min-width: 1221px) {
  .loader-text {
    left: 35%;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 }
  to { bottom:0; opacity:1 }
}

@keyframes animatebottom {
  from{ bottom:-100px; opacity:0 }
  to{ bottom:0; opacity:1 }
}