.form-floating {
  padding-top: 20px;
  position: relative;

  > label:not(.label-phone) {
    position: absolute;
    top: 17px;
    left: 0;
    height: 100%; // allow textareas
    padding: 8px 12px;
    pointer-events: none;
    border: 1px solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    font-size: em(18);
    color: var(--grey-6);
  }

  .label-phone {
    position: absolute;
    top: -2px;
    left: 0;
    height: 100%; // allow textareas
    pointer-events: none;
    border: 1px solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    font-size: em(12);
    color: var(--text-color);
  }
  // stylelint-disable no-duplicate-selectors
  > .form-control {
    &::placeholder {
      color: transparent;
    }
  }

  &.input-medium-width {
    width:400px;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label:not(.label-phone) {
      transform: translateY(-26px) translateX(-12px);
      font-size: em(12);
      color: var(--text-color);
    }
  }

  &.form-floating--icon {
    > label {
      left: 20px;
    }

    > .form-control:focus,
    > .form-control:not(:placeholder-shown),
    > .form-select {
      ~ label {
        transform: translateY(-26px) translateX(-32px);
      }
    }
  }
}