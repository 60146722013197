.list {

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-7);
    margin-top: $spacer-s;
    padding-bottom: $spacer-s;
  }
}
