.group-ordered-select {
  &-label, &-content {
    border-radius: 4px;
    margin-top: 1em;
    padding: 1em;
  }
  &-label {
    border: .1em dotted var(--ink-1);
    background-color: var(--grey-7);
  }
  &-content {
    border: .1em solid var(--ink-1);
  }

}