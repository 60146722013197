.datepicker__wrapper {
  position: relative;

  svg {
    position: absolute;
    top: 33px;
    left: 12px;
  }

  input[type="text"] {
    padding-left: 40px;
  }
}

/* override flatpickr */
.flatpickr-current-month {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
}
