.bic-code-split {
  margin: 0;

  &-label {
    border: none;
    background-color: transparent;
    @media (max-width: $mobile) {
      font-size: medium;
      padding: .5em;
      text-align: center;
    }
  }

  &-content {
    border: .1em solid var(--grey-7);;
    padding: 0 .5em;
    margin: 0;
    border-radius: 4px;

    &-portion {
      margin-top: 1em !important;
      margin-bottom: 1em !important;
      position: relative;
      padding: 0 1em !important;
      @media (max-width: $tab) {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
      }
      @media (max-width: $mobile) {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
      }
      label {
        position: absolute;

        &.label-top {
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
          top: -40%;
          transform: translateY(-50%);
          width: 100%;
          @media (max-width: $desk) {
            transform: translateX(2%) translateY(30%) !important;;
            text-align: left;
            .label-arrow {
              transform: rotate(-90deg) translateX(188px) translateY(-80px) !important;
            }
          }
          @media (max-width: $tab) {
            top: 5%;
            transform: translateX(90px) translateY(15px) !important;
            .label-arrow {
              transform: rotate(-90deg) translateX(325px) translateY(-270px) !important;
            }
          }
          @media (max-width: $mobile) {
            transform: translateX(10px) translateY(25px) !important;
            text-align: left;
            .label-arrow {
              transform: rotate(-90deg) translateX(180px) translateY(-60px) !important;
            }
          }
        }

        &.label-bottom {
          margin-top: 0 !important;
          padding-top: 0 !important;
          min-width: 100%;
        }

        > div {
          margin: 0 !important;
          padding: 0 !important;

          &.label-arrow {
            color: var(--ink-1-darken);
            font-size: large;
            line-height: em(10);
          }

          &.label-text {
            font-size: x-small;
            @media (max-width: $mobile) {
              font-size: smaller;
            }
          }
        }

      }

      input {
        margin: 0 !important;
        padding: 5px !important;
        text-align: center;
        @media (max-width: $mobile) {
          margin: 2em;
        }
      }

    }
  }

}
