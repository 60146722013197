// Padding

.u-pa0 { padding:          0 !important; }
.u-pt0 { padding-top:      0 !important; }
.u-pr0 { padding-right:    0 !important; }
.u-pb0 { padding-bottom:   0 !important; }
.u-px0 { padding-left:     0 !important; padding-right:  0 !important; }
.u-py0 { padding-top:      0 !important; padding-bottom: 0 !important; }

.u-pas { padding:          $spacer-s !important; }
.u-pts { padding-top:      $spacer-s !important; }
.u-prs { padding-right:    $spacer-s !important; }
.u-pbs { padding-bottom:   $spacer-s !important; }
.u-pxs { padding-left:     $spacer-s !important; padding-right:  $spacer-s !important; }
.u-pys { padding-top:      $spacer-s !important; padding-bottom: $spacer-s !important; }

.u-pab { padding:          $spacer !important; }
.u-ptb { padding-top:      $spacer !important; }
.u-prb { padding-right:    $spacer !important; }
.u-pbb { padding-bottom:   $spacer !important; }
.u-pxb { padding-left:     $spacer !important; padding-right:  $spacer !important; }
.u-pyb { padding-top:      $spacer !important; padding-bottom: $spacer !important; }

.u-pam { padding:          $spacer-m !important; }
.u-ptm { padding-top:      $spacer-m !important; }
.u-prm { padding-right:    $spacer-m !important; }
.u-pbm { padding-bottom:   $spacer-m !important; }
.u-pxm { padding-left:     $spacer-m !important; padding-right:  $spacer-m !important; }
.u-pym { padding-top:      $spacer-m !important; padding-bottom: $spacer-m !important; }

.u-pal { padding:          $spacer-l !important; }
.u-ptl { padding-top:      $spacer-l !important; }
.u-prl { padding-right:    $spacer-l !important; }
.u-pbl { padding-bottom:   $spacer-l !important; }
.u-pxl { padding-left:     $spacer-l !important; padding-right:  $spacer-l !important; }
.u-pyl { padding-top:      $spacer-l !important; padding-bottom: $spacer-l !important; }

.u-paxl { padding:          $spacer-xl !important; }
.u-ptxl { padding-top:      $spacer-xl !important; }
.u-prxl { padding-right:    $spacer-xl !important; }
.u-pbxl { padding-bottom:   $spacer-xl !important; }
.u-pxxl { padding-left:     $spacer-xl !important; padding-right:  $spacer-xl !important; }
.u-pyxl { padding-top:      $spacer-xl !important; padding-bottom: $spacer-xl !important; }
