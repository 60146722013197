.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;

    .pagination__action {
      @extend %button-reset;
      color: var(--ink-1);
      border: solid 1px #d7d7d7;
      border-radius: 0.2rem;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      text-align: center;
      padding: 0.4rem 0.8rem;

      &:hover {
        background: var(--ink-1);
        color: var(--white);
      }

      &:disabled {
        background: var(--ink-1-disabled);
        color: var(--white-disabled);
        opacity: .3;
        cursor: not-allowed;
      }
    }

    .pagination__number {
      display: flex;
      align-items: center;

      .number__item {
        @extend %button-reset;
        color: var(--ink-1);
        border: solid 1px #d7d7d7;
        border-radius: 0.2rem;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        text-align: center;
        padding: 0.4rem 0.8rem;
        margin-right: 0.5rem;

        &:hover,
        &--active {
          background: var(--ink-1);
          color: var(--white);
        }
      }

      .dots {
        margin-right: 0.5rem;
      }
    }

}

.pagination-showing {
  font-size: em(12);
  color: var(--gray-2);
}
