// Forms

%icon-position {
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 32px;
}

%icon-position-left {
  background-repeat: no-repeat;
  background-position: left 12px center;
  padding-left: 32px;
}

.readonly-element{
  pointer-events: none;
  opacity: 0.6;
}

// remove yellow
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 2000px 1000px var(--light) inset;
  background: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  outline: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

textarea,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
input[type="search"],
input[list],
select {
  display: block;
  width: 100%;
  padding: 8px 12px;

  font-family: var(--font-family-alt);
  color: var(--grey-3);
  font-size: em(14);
  letter-spacing: -0.5px;

  appearance: none;
  outline: 0;
  border: 1px solid var(--grey-7);
  border-radius: $border-radius-form;
  transition: color .3s ease;
  transition: .15s;
  background-color: transparent;
  box-shadow: $input-form;

  .password & {
    background-image: url(../../images/icons/password.png);
    @extend %icon-position-left;
  }

  .account & {
    background-image: url(../../images/icons/account.png);
    @extend %icon-position-left;
  }

  @include placeholder {
    color: rgba(var(--body-color), 0.8);
    transition: opacity .15s;
  }

  &:focus {
    border-color: var(--ink-1);
    box-shadow: $shadow-stroke-focus;

    @include placeholder {
      opacity: 0.4;
    }
  }

  &:disabled {
    color: var(--grey-6);
    background-color: var(--ink-9);
    border-color: var(--grey-7);
  }
}

input:not(:required):not(.flatpickr-input):read-only {
  color: var(--grey-6);
  background-color: var(--ink-9);
  border-color: var(--grey-7);
}

// remove arrow default
input::-webkit-calendar-picker-indicator {
  display: none;
}

input[list],
select {
background-image: url('../../images/icons/select.png');
background-repeat: no-repeat;
background-position: right .75rem center;
}

label {
font-size: em(12);
color: var(--text-color);
}

textarea {
resize: none;
height: auto;
}

label.radio-button {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;

  input {
    position: absolute;
    left: -9999px;
    visibility: hidden;

    &:checked + span {
      color: var(--ink-1);
      border: 1px solid var(--ink-1);
      background: var(--inactive-light);
      font-weight: 600;
    }

    &:disabled + span {
      color: var(--grey-6);
      border: 1px solid var(--grey-6);
      background: var(--light);
      cursor: default;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--grey-9);
    border-radius: $border-radius-form;
    transition: $transition-base;
    font-family: var(--font-family-title);
    text-transform: uppercase;
    font-weight: 500;

    &:hover {
      color: var(--ink-1);
      border: 1px solid var(--ink-1);
      background: var(--inactive-light);
      font-weight: 600;
    }
  }
}

label.radio-button-with-circle {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  height: 100%;

  input {
    position: absolute;
    left: -9999px;
    &:checked + span,
    &:hover + span {
      border-color: var(--ink-1);

      &:before {
        box-shadow: inset 0 0 0 1px var(--ink-1);
      }

      &:after {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #fff;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 16px;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 8px var(--ink-1);
        position: absolute;
        left: 20px;
      }
    }

    &:disabled + span {
      color: var(--grey-6);
      border-color: var(--grey-6);
      cursor: default;

      &:before {
        box-shadow: inset 0 0 0 1px var(--grey-6);
      }

      &:after {
        box-shadow: inset 0 0 0 8px var(--grey-6);
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px 12px 16px;
    border: 1px solid var(--grey-9);
    border-radius: $border-radius-base;
    transition: $transition-base;
    font-family: var(--font-family-title);
    font-weight: 500;

    &:hover {
      color: var(--ink-1);
      border-color: var(--ink-1);

      &:before {
        box-shadow: inset 0 0 0 0.125em var(--ink-1);
      }
    }

    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 16px;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em var(--grey-9);
    }
  }
}

label.radio-simple {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;

  input {
    position: absolute;
    top: 10px;
    left: -3px;
    z-index: -1;

    &:checked + span {
      &:before {
        box-shadow: inset 0 0 0 1px var(--ink-1);
      }

      &:after {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #fff;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 16px;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 8px var(--ink-1);
        position: absolute;
        left: 3px;
      }
    }

    &:disabled + span {
      color: var(--grey-6);

      &:before {
        box-shadow: inset 0 0 0 1px var(--grey-6);
      }

      &:after {
        box-shadow: inset 0 0 0 8px var(--grey-6);
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px 12px 0;
    color: var(--grey-3);
    font-size: em(14px);
    transition: $transition-base;
    font-family: var(--font-family-title);
    font-weight: 400;

    &:hover {
      &:before {
        box-shadow: inset 0 0 0 0.125em var(--ink-1);
      }
    }

    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 16px;
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 0.125em var(--grey-9);
    }
  }
}

.checkbox-toggle {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .checkbox-toggle__button {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .checkbox-toggle__button {
    outline: 0;
    display: block;
    height: 24px;
    position: relative;
    cursor: pointer;
    user-select: none;
    min-width: 40px;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 20px;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .checkbox-toggle__button:after {
    left: 47%;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    padding-left: 11px;
    font-family: var(--font-family-base);
    color: var(--grey-6);

    &--bold {
      font-size: em(14);
      font-weight: 700;
      color: var(--text-dark);
    }

    &--checked {
      color: var(--grey-5);
    }
  }
}

.checkbox-toggle--light {
  + .checkbox-toggle__button {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all .2s ease;
    }
  }

  &:checked + .checkbox-toggle__button {
    background: var(--ink-1);
  }

  &:checked + .checkbox-toggle__button {
    background: var(--ink-1-disable);
  }
}

.checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: bottom;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid var(--text-dark) !important;
    border-radius: $border-radius-form;
  }

  // Box hover
  &:hover + label:before,
  &:checked + label:before {
    background: var(--ink-1);
    border-color: var(--ink-1);
  }

  // Disabled state label.
  &:disabled + label {
    color: var(--grey-7);
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: var(--grey-7);
    border-color: var(--grey-6);
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 16px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white,
            4px -10px 0 white,
            4px -12px 0 white;
    transform: rotate(45deg);
  }

  &:disabled {
    &:checked + label:after {
      background: var(--grey-6);
      box-shadow:
              2px 0 0 var(--grey-6),
              4px 0 0 var(--grey-6),
              4px -2px 0 var(--grey-6),
              4px -4px 0 var(--grey-6),
              4px -6px 0 var(--grey-6),
              4px -8px 0 var(--grey-6),
              4px -10px 0 var(--grey-6),
              4px -12px 0 var(--grey-6);
      transform: rotate(45deg);
    }
  }
}

.datalist-choice {
  display: flex;
  flex-direction: column;
  max-height: 144px;
  border: 1px solid var(--grey-7);
  border-top: none;
  border-bottom-left-radius: $border-radius-form;
  border-bottom-right-radius: $border-radius-form;
  background: var(--light);
  overflow-y: scroll;

  position: absolute;
  z-index: 9999;
  width: calc(100% - 20px);

  &__item {
    cursor: pointer;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    font-family: var(--font-family-base);

    &:hover {
      background: #2684FF;
      color: hsl(0, 0%, 100%);
    }
  }
}

.help {
  margin-top: 2px;
  font-size: em(12);
  color: var(--text-dark);

  .success & {
    color: var(--success);
  }

  .error & {
    color: var(--error);
  }
}

.box-infos-validation {
  @extend %list-reset;
  margin-top: $spacer;
  padding: 11px 13px;
  width: fit-content;

  border: 1px solid var(--grey-8);
  border-radius: 8px;

  li {
    background-image: url(../../images/icons/check.png);
    background-repeat: no-repeat;
    background-position: left 7px center;
    padding-left: 25px;

    + li {
      margin-top: 12px;
    }

    &.valid {
      text-decoration: line-through;
    }
  }
}

.form--grey-7 {
  textarea,
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  input[type="search"],
  select,
  .checkbox + label:before {
    border-color: var(--grey-7);
  }
}

.form-label-input {
  display: grid;
  grid-template-columns: auto 96px;
  grid-gap: 31px;
  margin-bottom: 12px;

  label {
    font-size: em(18);
    color: var(--text-dark);
    align-self: end;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--grey-7);
  }
}

.success {
  position: relative;

  textarea,
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  input[type="search"],
  select {
    border-color: var(--success) !important;
    padding-right: 32px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 32px;
    right: 0;
    width: 32px;
    height: 17px;
    background-image: url(../../images/icons/success.png);
    @extend %icon-position;
  }

  &.no-label {
    &:after {
      top: 12px;
    }
  }
}

.error {
  position: relative;

  textarea,
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  input[type="search"],
  select {
    color: var(--error);
    border-color: var(--error) !important;
    padding-right: 32px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 32px;
    right: 0;
    width: 32px;
    height: 17px;
    background-image: url(../../images/icons/error.png);
    @extend %icon-position;
  }

  &.no-label {
    &:after {
      top: 12px;
    }
  }
}

.form-control__input {
  textarea {
    width: 100%;
    border: 1px solid var(--grey-5);

    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    background: var(--light);
  }
}


.form-control-center {
  margin:0 auto;
  display:block;
  text-align: center;
}
