.ui-success,.ui-error {
  width: 100px; height: 100px;
  margin: 40px auto;
}

.ui-success{
  &-circle {
    stroke-dasharray:260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    animation: ani-error-circle 1.2s linear;
  }
  &-path {
    stroke-dasharray: 60px 64px;
    stroke-dashoffset: 62px;
    stroke-linecap: round;
    animation: ani-success-path .4s 1s ease-in both;
  }
}

@keyframes ani-success-circle {
  0% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: -260.75219024795285px;
  }
  100% {
    stroke-dasharray: 260.75219024795285px, 0;
    stroke-dashoffset: -260.75219024795285px;
  }
}

@keyframes ani-success-path {
  0% {stroke-dashoffset: 62px;}
  65% {stroke-dashoffset: -5px;}
  84%{stroke-dashoffset: 4px;}
  100%{stroke-dashoffset: -2px;}
}

.ui-error{
  &-circle{
    stroke-dasharray:260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    animation: ani-error-circle 1.2s linear;
  }
  &-line1{
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line .15s 1.2s linear both;
  }
  &-line2{
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line .2s .9s linear both;
  }
}

@keyframes ani-error-line{
  to { stroke-dashoffset: 0; }
}

@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: -260.75219024795285px;
  }
  100% {
    stroke-dasharray: 260.75219024795285px, 0;
    stroke-dashoffset: -260.75219024795285px;
  }
}

.pj-preview  {
  stroke: var(--ink-1);
}

.pj-preview:hover {
  stroke: var(--ink-1-darken);
}

.pj-preview-fill  {
  fill: var(--ink-1);
}

.pj-preview-fill:hover {
  fill: var(--ink-1-darken);
}