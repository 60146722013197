.wrap {
  max-width: $wrapper-width + $spacer * 2;
  padding: 0 $spacer;
  margin: 0 auto;
}
.b-top {
  border-top-color: var(--ink-1) ;
  border-top-style: dotted;
  border-top-width: thin;
}
.b-bottom {
  border-bottom-color: var(--ink-1) ;
  border-bottom-style: dotted;
  border-bottom-width: thin;
}
