.pin {
  display: grid;
  grid-template-columns: 11fr 1fr;
  padding: 16px 24px;

  color: var(--text-dark);

  border-radius: 24px;

  &--info {
    background-color: var(--info-light);
  }

  &__icon {
    text-align: center;
    align-self: center;
  }
}
