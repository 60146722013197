.main-header {
  display: grid;
  grid-template-columns: 1fr min-content 1fr 1fr;

  .button {
    height: 40px;
    line-height: 19px;
  }

  .button--logout {
    display: initial;
    width: fit-content;
    min-width: auto;
    padding: 12px 12px;
  }

  .latest-item {
    grid-column: 3 / -1;
    justify-content: right !important;
  }

  .button-account {
    padding: 12px 12px;
  }

  .user-profile {
    color: var(--light);
    padding-right: 0.5rem;
  }
}

@include max-screen($large) {
  .text-logout {
    display: none !important;
  }
  .main-header {
    display: grid;
    grid-template-columns: 1fr min-content 0.8fr 1.2fr;
  }  
}

@include max-screen($tab-down) {
  .text-logout {
    display: none !important;
  }
  .main-header {
    display: grid;
    grid-template-columns: 1fr min-content min-content 1.8fr;
  }  
}
