.min-height-content {
  min-height: calc(100vh - 74px - 64px - 180px);
}

.min-height-content-create-folder {
  min-height: calc(100vh - 74px - 64px - 230px);
}

.min-height-content-folder {
  min-height: calc(100vh - 74px - 95px - 64px - 180px);
}

.box-elevations-wrapper--center {
  display: flex;
  justify-content: center;
}

.box-elevations {
  width: 100%;
  max-width: $max-width-wrapper;
  padding: 16px 24px;
  overflow: hidden;

  background: var(--light);
  border-radius: $box-shadow-radius;

  max-height: 40000px;
  transition: $transition-box;

  &--mb {
    margin-bottom: 40px;
  }

  &--shortstrong {
    box-shadow: $box-shadow-shortstrong;
  }

  &--high {
    box-shadow: $box-shadow-high;
  }

  &--medium {
    box-shadow: $box-shadow-medium;
  }

  &--soft {
    box-shadow: $box-shadow-soft;
  }

  &--login {
    box-shadow: $box-shadow-login;
    border-radius: $box-shadow-radius-base;
  }

  &--base {
    box-shadow: $box-shadow-base;
    border-radius: $box-shadow-radius-base;
  }

  &--closed {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    max-height: 0;
  }

  &--max-heigt-initial {
    max-height: initial;
  }

  &--no-padding {
    padding: 0 !important;
  }
}

.box-helpers {
  width: 100%;
  max-width: 576px;
  padding: 16px 24px 16px 16px;

  border: 1px solid var(--grey-7);
  box-shadow: $box-shadow-soft;
  background: var(--light);
}

.help-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .help__button {
    @extend %button-reset;
    position: relative;

    svg {
      margin-bottom: -8px;
    }
  }

  &--mark {
    justify-content: start;
    align-items: flex-start;
  }
}

@include min-screen($desk) {
  .box-elevations {
    padding: 32px 48px;
  }
}

.illustration {
  position: absolute;
  bottom: -33px;
  right: 0;
  opacity: 0.4;

  svg {
    max-width: 170px;
  }
}
