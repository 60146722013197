.card {
  display: flex;
  align-items: center;
  width: $max-width-wrapper;
  padding-left: 48px;
  padding-right: 48px;

  // animation
  max-height: 94px;
  overflow: hidden;
  transition: $transition-box;


  &--disabled {
    font-size: em(12);
  }

  &--disabled,
  &--completed {
    .card__title {
      color: var(--grey-6);
    }

    .card__content span {
      color: var(--text-color);
    }
  }

  .card__first {
    flex: auto;

    .card__title {
      margin-bottom: 12px;
    }
  }

  .card__second {
    display: flex;
    justify-content: space-around;
    height: 115px;
    align-items: center;
  }

  .card__third {
    width: 220px;
    display: flex;
    justify-content: space-between;

    &--column {
      flex-direction: column;
      align-items: center;
    }

    &--modify {
      width: 83px;
    }
  }

  .card__separation {
    width: 56px;
    height: 0px;
    left: 546px;
    top: 21px;

    border: 1px solid var(--grey-6);
    transform: rotate(90deg);
  }

  .card__content,
  .card__second,
  .card__third {
    display: none;
  }

  &--validated,
  &--completed {
    max-height: 500px;

    .card__content,
    .card__second,
    .card__third {
      display: flex !important;
    }
  }

  &--validated {
    &--summary {
      max-height: none;
      overflow: visible;
      width: auto;
      padding: 0;

      .card__first {
        width: 100%;
      }

      .card__second {
        width: 50px;
      }

      .card__third {
        width: 325px;
      }
    }
  }
}
