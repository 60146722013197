@include min-screen($desk) {
  div.wrapper-iframe {
    position: relative;
    width: auto;
    height: 755px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    > iframe.iframe {
      border: none;
      height: inherit;
      width: calc(100% + 11%);
      margin-left: calc(-5.5%);
      margin-right: calc(-5.5%);

      body {

        background-image: none !important;
        -moz-background-image: none;

        .top-area {
          background-color: var(--ink-1) !important;
        }

        .TabsHolde {
          background-color: var(--ink-1-darken) !important;
        }

        .DiaOSignDialog {
          border: none !important;
          box-shadow: none !important;
          -moz-border-radius: unset !important;
          -moz-box-shadow: none !important;
          -webkit-border-radius: unset !important;
          -webkit-box-shadow: none !important;
        }

        /*      #aspnetForm {
                #ds_Border {
                  & .scroll-area {
                    bottom: 0;
                  }

                  #ds_Tabs {
                    .MOB_removeMargin {
                      table {
                        tbody {
                          #ds_hldrBdy_pageSecureCheckInfoText {
                            .FieldPromptTextBold {
                              padding: 0px 10px;
                            }
                          }
                        }

                        #ds_hldrBdy_ContentRow {
                          .DiaOSignDialog {
                            border: none !important;
                            box-shadow: none !important;
                            -moz-border-radius: unset !important;
                            -moz-box-shadow: none !important;
                            -webkit-border-radius: unset !important;
                            -webkit-box-shadow: none !important;
                          }
                        }
                      }
                    }
                  }
                }

                #ds_FormBody {

                }
              }

              #ds_Footer {
              }*/
      }

      #ds_hldrBdy_ContentBar {

      }

      .btn-primary, .btn-main, .pointer {
        background-color: #582e60 !important;
        border-color: #582e60 !important;
        color: #ffffff !important;
      }

      .Btnprimary {
        background-color: var(--ink-1) !important;

        &:hover {
          background: var(--ink-1-light) !important;
        }
      }

    }
  }
}

@include max-screen($desk-down) {
  div.wrapper-iframe > iframe {
    border: none;
    height: inherit;
    width: calc(100% + 10.8%);
    margin-left: calc(-5.5%);
    margin-right: calc(-5.5%)
  }
}
