.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.modal {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 500px;
  width: 80%;
  padding: 1rem;
  border: 1px solid var(--ink-1);;
}

.modal-xl {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  width: 80%;
  padding: 1rem;
  border: 1px solid var(--ink-1);
  min-height: 80%;
  height: auto;
}

.modal-lg {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 900px;
  width: 80%;
  padding: 1rem;
  border: 1px solid var(--ink-1);
  min-height: 80%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-close-button {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 1.6rem;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
  transform: scale(1);
  transition: transform 300ms linear;
  &:hover {
    transform: scale(1.2);
  }
}

.modal-description {
  margin: var(--s)
}

.modal-container-buttons {
  display: flex;
  justify-content: space-between;
}

.content {
  justify-content: center;
  align-items: center;
  padding: 5% 2.5%;

  &__image {
    display: flex;
    justify-content: center;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  &__text {
    margin: 10% 5% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__specific-text {
    margin: 5%;
    display: flex;
    color: var(--error);
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.content-small {
  padding-top: 2.5% !important;
  padding-bottom: 0% !important;

  &__text {
    margin: 2% 2% 2% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

