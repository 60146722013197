@import "../base/variables";
@import "../base/mixins";

.main-footer {
  display: flex;
  justify-content: center;
  padding-top: $spacer-m;
  padding-bottom: $spacer-m;

  &__info {
    width: 100%;
    max-width: 792px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 24px 24px 0 24px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--ink-1-darken);
    }

    svg {
      margin-top: 5px;
      path {
        fill: var(--ink-1);
      }
    }

    &__address {
      display: flex;
      align-items: flex-start;
    }

    &__person {
      display: flex;
      position: relative;

      .main-footer__info__block {
        width: 50%;
        margin-right: 20px;

        & + .main-footer__info__block {
          position: relative;
          margin-right: 0;
          margin-left: 20px;

          &:before {
            content: '';
            position: absolute;
            left: -20px;
            width: 1px;
            height: 100%;
            background: var(--ink-1);
          }
        }

        p {
          margin-bottom: 8px;
        }
      }
    }
  }
}

@include min-screen($desk) {
  .main-footer__info {
    padding: 50px 0 0 0;
  }
}
